import { ReplaySubject } from 'rxjs';

type ValuesOf<T> = T[keyof T];

export type ABTestTaskListType = keyof typeof abTestList;
export type HeaderMainGroupType = ValuesOf<{
  [K in keyof typeof abTestList]: typeof abTestList[K]['headerMainGroup'];
}>;
export type ABVariantsType = ValuesOf<{
  [K in keyof typeof abTestList]: ValuesOf<typeof abTestList[K]['abTestGroupIds']>;
}>;

export interface ABTestItem<K extends keyof typeof abTestList> {
  nameOfTask?: ABTestTaskListType;
  alias?: string;
  headerMainGroup: HeaderMainGroupType;
  abTestVariants: ABVariantsType[];
  abTestGroupIds: Record<keyof typeof abTestList[K]['abTestGroupIds'], ABVariantsType>;
  resolvedValue?: ABVariantsType;
  resolvedValue$?: ReplaySubject<ABVariantsType>;
  resolvedCookieValue?: ABVariantsType;
  syncOnAuth?: boolean;
  debug?: boolean;
};

export const AB_TEST_LIST: {
  [K in keyof typeof abTestList]: ABTestItem<K>;
} = {} as any;

export const abTestList = {
  'CUSTOM_FLOW': {
    alias: 'CUSTOM_FLOW',
    headerMainGroup: 'ID352',
    abTestGroupIds: {
      V1: 'ID352',
      V2: 'ID353',
    },
    syncOnAuth: true,
    debug: false,
  },
  'DEVBIT4131': {
    alias: 'DEVBIT4131',
    headerMainGroup: 'ID740',
    abTestGroupIds: {
      V1: 'ID740',
      V2: 'ID741',
    },
    syncOnAuth: true,
    debug: false,
  },
  'DEVBIT4373': {
    alias: 'DEVBIT4373',
    headerMainGroup: 'ID761',
    abTestGroupIds: {
      V1: 'ID761',
      V2: 'ID762',
    },
    syncOnAuth: true,
    debug: false,
  },
  'DEVBIT4367': {
    alias: 'DEVBIT4367',
    headerMainGroup: 'ID766',
    abTestGroupIds: {
      V1: 'ID766',
      V2: 'ID767',
    },
    syncOnAuth: true,
    debug: false,
  },
} as const;
